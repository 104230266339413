.desktop {
  background-color: #131415;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.logo-white {
  height: 56px;
  object-fit: cover;
  width: 226px;
}

.main-axis {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 38px;
  justify-content: space-between;
  gap: 15px;
}

.main-axis-container {
  width: 968px;
}

.second-axis-container {
  width: 343px;
  margin-left: 30px;
}

.stat-item {
  background-color: #181a23;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 308px;
  height: 120px;
  padding: 30px;
  border-radius: 20px;
  box-sizing: border-box;
}

.stat-item-title {
  font-family: 'Poppins';
  font-size: 16px;
  margin: 0x;
  padding: 0px;
  padding-bottom: 10px;
  font-weight: 400;
  color: #8C89B4;
}

.stat-item-text {
  font-family: 'Montserrat';
  font-size: 35px;
  font-weight: 700;
  margin: 0x;
  padding: 0px;
  color: #DFE0ED;
}

.stat-item-change {
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 400;
  padding: 0px;
  margin: 0px;
  color: #45B37C;
}

.stat-item-change-box {
  background-color: rgba(2, 177, 90, 0.15);
  padding: 5px;
  border-radius: 10px;
}

.content-title {
  font-family: 'Poppins';
  font-size: 24px;
  font-weight: 600;
  color: #DFE0ED;
  margin-top: 22px;
  margin-left: 34px;
}

.blog-item {
  border-radius: 20px;
  padding: 15px;
}

.blog-item-image {
  background-color: #282935;
  border-radius: 20px;
}

.blog-item-details {
  margin-top: 20px;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
}

.blog-item-details-internal {
  display: flex;
  flex: 0 0 auto;
  position: relative;
  width: 100%;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
}

.blog-item-category-box {
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #330621;
  display: inline-block;
  border-radius: 50px;
}

.blog-item-category {
  color: #ed1999;
  font-family: "Poppins", Helvetica;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.9px;
  line-height: normal;
  padding-left: 5px;
  padding-right: 5px;
  margin: 5px;
}

.blog-item-title {
  color: #dfdfed;
  font-family: "Poppins", Helvetica;
  line-height: normal;
  letter-spacing: normal;
  font-size: 18px;
  font-weight: 600;
}

.blog-item-description {
  color: #b8b9c3;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  line-height: normal;
  letter-spacing: normal;
  font-weight: 400;
  line-height: 15px;
}

.blog-item-date {
  color: #b8b9c3;
  font-family: "Poppins", Helvetica;
  font-size: 12px;
  font-weight: 500;
  margin-top: 20px;
}

.view-all-button {
  width: 205px;
  height: 40px;
  background-color: #9C2CF3;
  border-radius: 10px;
  align-self: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.view-all-button-text {
  color: white;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 600;
}

.about-text {
  color: #b8b9c3;
  font-family: "Poppins", Helvetica;
  font-size: 10px;
  font-weight: 400;
  width: 80%;
  margin-left: 50px;
  margin-bottom: 50px;
  letter-spacing: 0;
  line-height: normal;
}

.span {
  color: #b8b9c3;
  font-family: "Poppins", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
}

.about-sub-title {
  font-size: 18px;
  font-weight: 700;
}

.about-text-bold {
  font-size: 18px;
  font-weight: 700;
}

.overview-item-title {
  font-family: 'Poppins';
  font-size: 12px;
  font-weight: 400;
  color: #999999;
}

.overview-item-table-header {
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  display: flex;
  flex: 0 0 auto;
  width: 100%;
  padding: 0px;
  margin: 0px;
  padding-left: 30px;
  padding-right: 30px;
}

.overview-item-column {
  align-items: center;
  display: flex;
  padding: 8px;
  position: relative;
  width: 115px;
}

.overview-item-list {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px;
  margin: 0px;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
}

.overview-item-row {
  align-items: flex-start;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--neutral-600);
  border-top-style: solid;
  border-top-width: 1px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  position: relative;
  width: 100%;
}

.overview-item {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 8px;
  padding: 10px 8px;
  position: relative;
  width: 115px;
}

.overview-item-text {
  color: var(--neutralwhite);
  font-family: "Poppins", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18px;
  margin-top: -0.43px;
  text-align: start;
  white-space: nowrap;
  width: 100%;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .stat-item {
    width: 45%;
    /* Two items per row */
  }
}

@media (max-width: 768px) {
  .stat-item {
    width: 100%;
    /* Stack items vertically on small screens */
  }
}

@keyframes digitRoll {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }

  50% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.digit-roll {
  display: inline-block;
  animation: digitRoll 0.5s ease-out;
  min-width: 12px;
  /* Adjust width if necessary */
  margin-top: 10px;
  margin: 0px;
  padding: 0px;
  text-align: center;
}

/* styles.css */
/* styles.css */

.radio-label {
  position: relative;
  cursor: pointer;
  padding-left: 30px;
  /* Space for the custom radio button */
  display: inline-flex;
  align-items: center;
}

.radio-input {
  appearance: none;
  /* Remove default styling */
  border: 2px solid #DFE0ED;
  /* Default border */
  border-radius: 50%;
  /* Make it round */
  width: 20px;
  /* Size */
  height: 20px;
  /* Size */
  position: relative;
  /* Position for the inner circle */
}

.radio-input:checked {
  border-color: #8C52FF;
  /* Change border color when checked */
}

.radio-input:checked::after {
  content: '';
  position: absolute;
  left: 2.5px;
  top: 2.5px;
  width: 12px;
  height: 12px;
  background-color: #8C52FF;
  border-radius: 50%;
  /* Round shape */
}

.scrolling-text-container {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  padding: 10px;
}

.scrolling-text {
  display: inline-block;
  font-size: 16px;
  color: #FFFFFF;
  will-change: transform;
  animation: scrollText 30s linear infinite;
}

.scrolling-text:hover {
  animation-play-state: paused;
}

/* Keyframes for the scrolling animation */
@keyframes scrollText {
  0% {
    transform: translateX(100%);
    /* Start from right outside the screen */
  }

  100% {
    transform: translateX(-100%);
    /* Move completely to the left outside the screen */
  }
}

.color-changing-circle {
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  animation: blink 1.5s ease-in-out infinite;
}


@keyframes blink {
  0%, 100% {
  opacity: 0;
         }
  40%, 70% {
  opacity: 1;
         }
     }